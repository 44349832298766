import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCzdIRuT6XZ94qWMcLyFE7KY0bM1MCwK6w",
  authDomain: "huddle-scheduler.firebaseapp.com",
  projectId: "huddle-scheduler",
  storageBucket: "huddle-scheduler.firebasestorage.app",
  messagingSenderId: "618122545313",
  appId: "1:618122545313:web:16a833d18df520f071c8e0"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app); 