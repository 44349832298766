import React, { useState, useRef } from 'react';
import { getUserTimezone } from '../utils/helpers';

const WeeklyGrid = ({ 
  isCreator = false, 
  onSelectionChange, 
  timeSettings = {}, 
  creatorSlots = {}, 
  responses = [],
  showHeatmap = false 
}) => {
  const [selectedSlots, setSelectedSlots] = useState({});
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [isDragging, setIsDragging] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dragStart, setDragStart] = useState(null);
  const dragStateRef = useRef({ isSelecting: null });
  
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const userTimezone = getUserTimezone();
  
  const generateTimeSlots = (start, end) => {
    const times = [];
    const [startHour] = start.split(':').map(Number);
    const [endHour] = end.split(':').map(Number);
    
    for (let hour = startHour; hour <= endHour; hour++) {
      times.push(`${hour.toString().padStart(2, '0')}:00`);
      times.push(`${hour.toString().padStart(2, '0')}:30`);
    }
    return times;
  };

  const times = generateTimeSlots(
    timeSettings.startTime || startTime,
    timeSettings.endTime || endTime
  );

  const calculateHeatmap = () => {
    const heatmap = {};
    days.forEach(day => {
      times.forEach(time => {
        const key = `${day}-${time}`;
        let count = 0;
        responses.forEach(response => {
          if (response.availability[key]) count++;
        });
        heatmap[key] = count;
      });
    });
    return heatmap;
  };

  const heatmapData = showHeatmap ? calculateHeatmap() : {};

  const getHeatmapColor = (count) => {
    if (count === 0) return 'bg-gray-800';
    const intensity = Math.min((count / responses.length) * 255, 255);
    return `rgb(${255 - intensity}, ${255 - intensity}, 255)`;
  };

  const handleMouseDown = (day, time) => {
    if (!isCreator && !creatorSlots[`${day}-${time}`]) {
      return;
    }

    setIsDragging(true);
    setDragStart({ day, time });
    
    const key = `${day}-${time}`;
    dragStateRef.current.isSelecting = !selectedSlots[key];
    
    const newSelectedSlots = {
      ...selectedSlots,
      [key]: dragStateRef.current.isSelecting
    };
    
    setSelectedSlots(newSelectedSlots);
    onSelectionChange({
      slots: newSelectedSlots,
      startTime,
      endTime
    });
  };

  const handleMouseEnter = (day, time) => {
    if (!isDragging || (!isCreator && !creatorSlots[`${day}-${time}`])) return;

    const key = `${day}-${time}`;
    const newSelectedSlots = {
      ...selectedSlots,
      [key]: dragStateRef.current.isSelecting
    };
    
    setSelectedSlots(newSelectedSlots);
    onSelectionChange({
      slots: newSelectedSlots,
      startTime,
      endTime
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStart(null);
  };

  React.useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, []);

  return (
    <div className="text-gray-200">
      {/* Timezone indicator */}
      <div className="mb-4 text-sm flex items-center bg-gray-800 p-3 rounded-lg">
        <span className="mr-2">🌍</span>
        Times shown in your timezone: <span className="font-medium ml-1">{userTimezone.replace('_', ' ')}</span>
      </div>

      {isCreator && (
        <div className="mb-6 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Start Time
            </label>
            <select 
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="mt-1 block w-full rounded-lg border-gray-700 bg-gray-800 
                       text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={`${i.toString().padStart(2, '0')}:00`} className="text-gray-200 bg-gray-800">
                  {`${i.toString().padStart(2, '0')}:00`}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              End Time
            </label>
            <select 
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="mt-1 block w-full rounded-lg border-gray-700 bg-gray-800 
                       text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={`${i.toString().padStart(2, '0')}:00`} className="text-gray-200 bg-gray-800">
                  {`${i.toString().padStart(2, '0')}:00`}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {showHeatmap && (
        <div className="mb-4 flex items-center gap-2 bg-gray-800 p-2 rounded-lg">
          <span className="text-sm">Response count:</span>
          <div className="flex items-center gap-1">
            <div className="w-4 h-4 bg-gray-800 border border-gray-700"></div>
            <span className="text-xs">0</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-4 h-4" style={{ backgroundColor: 'rgb(200, 200, 255)' }}></div>
            <span className="text-xs">Few</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-4 h-4" style={{ backgroundColor: 'rgb(100, 100, 255)' }}></div>
            <span className="text-xs">Many</span>
          </div>
        </div>
      )}

      <div className="overflow-x-auto rounded-lg bg-gray-800">
        <div className="min-w-max select-none">
          <div className="grid grid-cols-[100px_repeat(7,_1fr)] bg-gray-800/80">
            <div className="p-2 font-semibold border-b border-r border-gray-700">Time</div>
            {days.map(day => (
              <div key={day} className="p-2 font-semibold border-b border-r border-gray-700 text-center">
                {day}
              </div>
            ))}
          </div>

          {times.map(time => (
            <div key={time} className="grid grid-cols-[100px_repeat(7,_1fr)]">
              <div className="p-2 border-b border-r border-gray-700 text-sm">{time}</div>
              {days.map(day => {
                const key = `${day}-${time}`;
                const isAvailable = isCreator || creatorSlots[key];
                const isSelected = selectedSlots[key];
                const responseCount = heatmapData[key] || 0;

                return (
                    <div
                    key={key}
                    onMouseDown={() => handleMouseDown(day, time)}
                    onMouseEnter={() => handleMouseEnter(day, time)}
                    className={`border-b border-r border-gray-700 transition-colors relative ${
                        !isCreator && !isAvailable 
                        ? 'bg-gray-900/50 cursor-not-allowed' 
                        : isSelected
                        ? 'bg-blue-500 hover:bg-blue-600 cursor-pointer'
                        : showHeatmap
                        ? 'cursor-pointer'
                        : 'hover:bg-gray-700 cursor-pointer'
                    }`}
                    style={showHeatmap ? { backgroundColor: getHeatmapColor(responseCount) } : {}}
                    >
                    {/* Add X mark for unavailable slots */}
                    {(!isCreator && !isAvailable) && (
                        <div className="absolute inset-0 flex items-center justify-center text-gray-600">
                        <svg 
                            className="w-3 h-3" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M6 18L18 6M6 6l12 12" 
                            />
                        </svg>
                        </div>
                    )}
                    {showHeatmap && responseCount > 0 && (
                        <div className="w-full h-full flex items-center justify-center text-xs font-medium">
                        {responseCount}
                        </div>
                    )}
                    </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyGrid;