import React, { useMemo } from 'react';
import { getUserTimezone } from '../utils/helpers';

const SummaryView = ({ responses, creatorSlots, timeSettings, viewOnly = false }) => {
  const userTimezone = getUserTimezone();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        const button = document.getElementById(text);
        if (button) {
          button.textContent = 'Copied!';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 2000);
        }
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  // Calculate best times
  const getBestTimes = useMemo(() => {
    const availability = {};
    
    // Initialize slots from creator's availability
    Object.keys(creatorSlots).forEach(slot => {
      if (creatorSlots[slot]) {
        availability[slot] = { count: 0, people: [] };
      }
    });

    // Count responses for each slot
    responses.forEach(response => {
      Object.keys(response.availability).forEach(slot => {
        if (response.availability[slot] && availability[slot]) {
          availability[slot].count++;
          availability[slot].people.push(response.name);
        }
      });
    });

    // Convert to array and sort by count
    return Object.entries(availability)
      .map(([slot, data]) => ({
        slot,
        count: data.count,
        people: data.people,
        percentage: responses.length ? (data.count / responses.length) * 100 : 0
      }))
      .sort((a, b) => 
        b.count - a.count || // Sort by count first
        a.slot.localeCompare(b.slot) // Then alphabetically
      );
  }, [responses, creatorSlots]);

  const formatTimeSlot = (slot) => {
    const [day, time] = slot.split('-');
    return `${day} at ${time}`;
  };

  return (
    <div className="space-y-8 text-gray-200">
      {/* Timezone Indicator */}
      <div className="bg-gray-800 rounded-lg p-4">
        <div className="flex items-center">
          <span className="mr-2">🌍</span>
          <p className="text-sm">
            All times are shown in your local timezone: <span className="font-medium">{userTimezone.replace('_', ' ')}</span>
          </p>
        </div>
      </div>

      {/* Share Links */}
      {!viewOnly && (
        <div className="flex gap-4 bg-gray-800 p-4 rounded-lg">
          <button
            onClick={() => copyToClipboard(`${window.location.origin}/?poll=${window.location.search.split('poll=')[1].split('&')[0]}`)}
            className="flex items-center px-4 py-2 text-sm font-medium text-emerald-400 border border-emerald-400/20 
                     rounded-lg hover:bg-emerald-400/10 transition-colors"
          >
            Copy Response Link
          </button>
          <button
            onClick={() => copyToClipboard(`${window.location.origin}/?poll=${window.location.search.split('poll=')[1].split('&')[0]}&viewOnly=true`)}
            className="flex items-center px-4 py-2 text-sm font-medium text-purple-400 border border-purple-400/20 
                     rounded-lg hover:bg-purple-400/10 transition-colors"
          >
            Copy View-Only Link
          </button>
        </div>
      )}

      {/* Overall Stats */}
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-lg font-medium mb-2">Response Summary</h3>
        <p className="text-sm text-gray-300">
          Total Responses: {responses.length}
        </p>
      </div>

      {/* Best Times Section */}
      <div>
        <h3 className="text-lg font-medium mb-4">Best Times</h3>
        <div className="space-y-4">
          {getBestTimes.map(({ slot, count, people, percentage }) => (
            count > 0 && (
              <div 
                key={slot} 
                className="border border-gray-700 rounded-lg p-4 bg-gray-800 hover:bg-gray-800/80 transition-colors"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-white">{formatTimeSlot(slot)}</span>
                  <span className="text-sm text-gray-400">
                    {count} {count === 1 ? 'person' : 'people'} ({Math.round(percentage)}%)
                  </span>
                </div>
                
                {/* Progress bar */}
                <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
                  <div 
                    className="bg-blue-500 h-2 rounded-full transition-all duration-500" 
                    style={{ width: `${percentage}%` }}
                  />
                </div>

                {/* Attendee list */}
                {!viewOnly && people.length > 0 && (
                  <div className="text-sm text-gray-400 mt-2">
                    <span className="font-medium text-gray-300">Available:</span> {people.join(', ')}
                  </div>
                )}
              </div>
            )
          ))}

          {getBestTimes.every(time => time.count === 0) && (
            <div className="text-center py-8 text-gray-400">
              <p className="text-sm">
                No responses yet. Share the schedule link to collect availability.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Tips Section */}
      {!viewOnly && (
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-sm font-medium text-white mb-2">Quick Tips</h4>
          <ul className="text-sm text-gray-400 space-y-1">
            <li>• Use the "Copy Link" button above to share this schedule</li>
            <li>• The view-only link shows the summary without names</li>
            <li>• Responses are automatically converted to each person's timezone</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SummaryView;