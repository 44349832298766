import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, addDoc, getDoc, doc, updateDoc } from 'firebase/firestore';
import WeeklyGrid from './components/WeeklyGrid';
import SummaryView from './components/SummaryView';
import SuccessModal from './components/SuccessModal';
import { generateUID } from './utils/helpers';

// Link sharing modal component
const LinkCard = ({ links, onClose }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        const button = document.getElementById(text);
        if (button) {
          button.textContent = 'Copied!';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 2000);
        }
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-xl shadow-2xl p-6 max-w-md w-full text-white">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-medium">Schedule Created!</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            ✕
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Share this link to collect responses:
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                readOnly
                value={links.response}
                className="flex-1 p-3 text-sm border rounded-lg bg-gray-800 border-gray-700 text-gray-200"
              />
              <button
                id={links.response}
                onClick={() => copyToClipboard(links.response)}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Copy
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              View-only link (shows only summary):
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                readOnly
                value={links.viewOnly}
                className="flex-1 p-3 text-sm border rounded-lg bg-gray-800 border-gray-700 text-gray-200"
              />
              <button
                id={links.viewOnly}
                onClick={() => copyToClipboard(links.viewOnly)}
                className="px-4 py-2 text-sm font-medium text-white bg-purple-500 rounded-lg hover:bg-purple-600 transition-colors"
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [eventName, setEventName] = useState('');
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState({});
  const [userName, setUserName] = useState('');
  const [pollId, setPollId] = useState(null);
  const [pollData, setPollData] = useState(null);
  const [creatorId, setCreatorId] = useState(localStorage.getItem('creatorId'));
  const [viewMode, setViewMode] = useState('grid');
  const [showLinks, setShowLinks] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [links, setLinks] = useState({ response: '', viewOnly: '' });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('poll');
    const viewOnly = params.get('viewOnly');
    
    if (id) {
      setPollId(id);
      fetchPollData(id, viewOnly);
    }
  }, []);

  const fetchPollData = async (id, viewOnly) => {
    try {
      const pollDoc = await getDoc(doc(db, "schedules", id));
      if (pollDoc.exists()) {
        const data = pollDoc.data();
        setPollData(data);
        
        if (viewOnly) {
          setViewMode('summary');
        }
      }
    } catch (error) {
      console.error("Error fetching poll:", error);
      alert("Error fetching poll data. Please try again.");
    }
  };

  const createNewSchedule = async (e) => {
    e.preventDefault();
    if (!eventName) {
      alert('Please enter an event name');
      return;
    }

    setLoading(true);
    try {
      const newCreatorId = generateUID();
      localStorage.setItem('creatorId', newCreatorId);
      setCreatorId(newCreatorId);

      const docRef = await addDoc(collection(db, "schedules"), {
        name: eventName,
        createdAt: new Date(),
        timeSettings: {
          startTime: availability.startTime,
          endTime: availability.endTime
        },
        creatorSlots: availability.slots,
        creatorId: newCreatorId,
        responses: []
      });

      const baseUrl = window.location.origin;
      setLinks({
        response: `${baseUrl}/?poll=${docRef.id}`,
        viewOnly: `${baseUrl}/?poll=${docRef.id}&viewOnly=true`
      });
      setShowLinks(true);
    } catch (error) {
      console.error("Error creating schedule:", error);
      alert(`Error creating schedule: ${error.message}`);
    }
    setLoading(false);
  };

  const submitAvailability = async (e) => {
    e.preventDefault();
    if (!userName) {
      alert('Please enter your name');
      return;
    }

    setLoading(true);
    try {
      const pollRef = doc(db, "schedules", pollId);
      const currentPoll = await getDoc(pollRef);
      const currentData = currentPoll.data();

      await updateDoc(pollRef, {
        responses: [...(currentData.responses || []), {
          name: userName,
          availability: availability.slots,
          submittedAt: new Date()
        }]
      });

      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error submitting availability:", error);
      alert("Error submitting availability. Please try again.");
    }
    setLoading(false);
  };

  // If we're viewing an existing poll
  if (pollId && pollData) {
    const isCreator = pollData.creatorId === creatorId;
    const isViewOnly = new URLSearchParams(window.location.search).get('viewOnly');

    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 py-6">
        <div className="max-w-6xl mx-auto px-4">
          <div className="bg-gray-900 rounded-xl shadow-2xl p-8 text-white">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                {pollData.name}
              </h1>
              {isCreator && (
                <div className="flex gap-4">
                  <button
                    onClick={() => setViewMode(viewMode === 'grid' ? 'summary' : 'grid')}
                    className="px-4 py-2 text-sm font-medium border border-blue-400/20 rounded-lg 
                             hover:bg-gray-800 transition-all duration-200"
                  >
                    {viewMode === 'grid' ? 'View Summary' : 'View Grid'}
                  </button>
                </div>
              )}
            </div>

            {/* Quick Guide */}
            {!isViewOnly && (
              <div className="mb-6 p-4 bg-gray-800 rounded-lg text-gray-300">
                <h3 className="font-medium mb-2">Quick Guide:</h3>
                <ul className="space-y-1 text-sm">
                  {isCreator ? (
                    <>
                      <li>• View all responses in the grid or summary view</li>
                      <li>• Share the schedule link to collect more responses</li>
                      <li>• Use the view-only link to share results without names</li>
                    </>
                  ) : (
                    <>
                      <li>• Enter your name below</li>
                      <li>• Click or drag to select times you're available</li>
                      <li>• Submit your availability when done</li>
                    </>
                  )}
                </ul>
              </div>
            )}

            {isCreator && viewMode === 'summary' ? (
              <SummaryView 
                responses={pollData.responses || []}
                creatorSlots={pollData.creatorSlots}
                timeSettings={pollData.timeSettings}
              />
            ) : !isViewOnly ? (
              <form onSubmit={submitAvailability} className="space-y-6">
                {!isViewOnly && (
                  <div>
                    <label className="block text-lg font-medium text-gray-200 mb-2">
                      Your Name
                    </label>
                    <input
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      className="w-full text-xl px-4 py-3 bg-gray-800 border-2 border-gray-700 rounded-lg 
                               focus:border-blue-400 focus:ring-2 focus:ring-blue-400/50 text-white 
                               placeholder-gray-400 transition-all duration-300"
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                )}
                
                <div className="mt-6">
                  <label className="block text-lg font-medium text-gray-200 mb-4">
                    Select Your Availability
                  </label>
                  <WeeklyGrid 
                    isCreator={false}
                    onSelectionChange={setAvailability}
                    timeSettings={pollData.timeSettings}
                    creatorSlots={pollData.creatorSlots}
                    responses={isCreator ? pollData.responses : []}
                    showHeatmap={isCreator}
                  />
                </div>

                {!isViewOnly && (
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full flex justify-center py-4 px-6 rounded-lg text-lg font-medium
                             text-white bg-gradient-to-r from-blue-500 to-purple-600 
                             hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 
                             focus:ring-offset-2 focus:ring-blue-500 shadow-lg transform 
                             transition-all duration-200 hover:scale-[1.02]"
                  >
                    {loading ? 'Submitting...' : 'Submit Availability'}
                  </button>
                )}
              </form>
            ) : (
              <SummaryView 
                responses={pollData.responses || []}
                creatorSlots={pollData.creatorSlots}
                timeSettings={pollData.timeSettings}
                viewOnly={true}
              />
            )}
          </div>
        </div>
        
        {showSuccessModal && (
          <SuccessModal onClose={() => window.location.reload()} />
        )}
      </div>
    );
  }

  // Creator's view (new schedule)
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 py-6">
      <div className="max-w-6xl mx-auto px-4">
        <div className="bg-gray-900 rounded-xl shadow-2xl p-8 text-white">
          <h1 className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Schedule Creator
          </h1>
          <p className="text-gray-300 mb-8">
            Create a new schedule and share it with your participants.
          </p>

          {/* Quick Guide */}
          <div className="mb-6 p-4 bg-gray-800 rounded-lg text-gray-300">
            <h3 className="font-medium mb-2">Quick Guide:</h3>
            <ul className="space-y-1 text-sm">
              <li>1. Enter your event name</li>
              <li>2. Select time range using the dropdowns</li>
              <li>3. Click or drag to select available time slots</li>
              <li>4. Create and share the schedule</li>
            </ul>
          </div>

          <form onSubmit={createNewSchedule} className="space-y-8">
            <div>
              <label className="block text-lg font-medium text-gray-200 mb-3">
                Event Name
              </label>
              <input
                type="text"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="w-full text-2xl px-6 py-4 bg-gray-800 border-2 border-gray-700 rounded-lg 
                          focus:border-blue-400 focus:ring-2 focus:ring-blue-400/50 text-white 
                          placeholder-gray-400 transition-all duration-300"
                placeholder="Enter your event name"
                required
              />
            </div>
            
            <div className="mt-8">
              <label className="block text-lg font-medium text-gray-200 mb-4">
                Select Available Time Slots
              </label>
              <WeeklyGrid 
                isCreator={true}
                onSelectionChange={setAvailability}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-4 px-6 rounded-lg text-lg font-medium
                       text-white bg-gradient-to-r from-blue-500 to-purple-600 
                       hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 
                       focus:ring-offset-2 focus:ring-blue-500 shadow-lg transform 
                       transition-all duration-200 hover:scale-[1.02]"
            >
              {loading ? 'Creating...' : 'Create Schedule'}
            </button>
          </form>
        </div>
      </div>

      {showLinks && (
        <LinkCard 
          links={links} 
          onClose={() => {
            setShowLinks(false);
            window.location.href = `/?poll=${links.response.split('poll=')[1]}`;
          }} 
        />
      )}
    </div>
  );
}

export default App;