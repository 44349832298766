// src/components/SuccessModal.js
const SuccessModal = ({ onClose }) => {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="bg-gray-900 rounded-xl shadow-2xl p-6 max-w-md w-full text-white">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
              <div className="bg-green-500/20 rounded-full p-2 mr-3">
                <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h3 className="text-xl font-medium">Availability Submitted!</h3>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200 transition-colors"
            >
              ✕
            </button>
          </div>
          <p className="text-gray-300 mb-6">
            Thanks for submitting your availability. The organizer will be able to see your response.
          </p>
          <button
            onClick={onClose}
            className="w-full py-2 px-4 bg-gradient-to-r from-blue-500 to-purple-600 
                     hover:from-blue-600 hover:to-purple-700 rounded-lg font-medium 
                     transition-all duration-200"
          >
            Done
          </button>
        </div>
      </div>
    );
  };
  
  export default SuccessModal;